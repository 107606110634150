import { useMutation } from '@tanstack/react-query';

import {
  AddOnCreditsCheckoutPayload,
  AddOnCreditsPayment,
  ApiError,
  createAddOnSearchQueriesCheckoutSession,
} from '@/lib/api';

const postCreateAddOnSearchQueriesCheckout = async (
  payload: AddOnCreditsCheckoutPayload
) => {
  const { data } = await createAddOnSearchQueriesCheckoutSession(payload);
  return data;
};

export const useCreateAddOnSearchQueriesCheckout = () =>
  useMutation<
    AddOnCreditsPayment,
    ApiError,
    AddOnCreditsCheckoutPayload,
    unknown
  >({
    mutationFn: postCreateAddOnSearchQueriesCheckout,
  });
