import { InputHTMLAttributes, MouseEvent, forwardRef } from 'react';

import { Search, X } from 'lucide-react';

import { cn } from '@/lib/utils';

import { Input } from './ui/input';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  onClear?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const SearchInput = forwardRef<HTMLInputElement, Props>(
  ({ className, value, onClear, ...rest }, ref) => {
    return (
      <div className={cn('relative sm:w-[260px]', className)}>
        <Search className="absolute left-[13px] top-[50%] h-4 w-4 translate-y-[-50%] text-primary" />

        <Input
          ref={ref}
          placeholder="Search"
          className="w-full rounded-lg px-9"
          value={value}
          {...rest}
        />
        {value && (
          <button
            className="absolute right-2 top-[50%] translate-y-[-50%]"
            onClick={onClear}
            type="button"
          >
            <X className="h-4 w-4 text-primary" />
          </button>
        )}
      </div>
    );
  }
);
