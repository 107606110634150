import { useEffect } from 'react';
import { ProductFruits } from 'react-product-fruits';
import { Outlet } from 'react-router-dom';

import { Elements } from '@stripe/react-stripe-js';

import { Toaster } from '@/components/ui/toaster';
import { useUserContext } from '@/context/user';
import { stripePromise } from '@/lib/stripe';

export const Root = () => {
  const { user } = useUserContext();

  useEffect(() => {
    window.profitwell('start', { user_email: user?.email });

    window.Intercom('boot', {
      app_id: import.meta.env.VITE_INTERCOM_APP_ID,
      name: user?.name,
      email: user?.email,
      created_at: user?.createdAt,
    });
  }, [user]);

  const userInfo = {
    username: user?.email || 'guest',
    email: user?.email,
  };

  // const postHogOptions = {
  //   api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  // };

  return (
    <Elements stripe={stripePromise}>
      {/* <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={postHogOptions}
      > */}
      <Toaster />
      <ProductFruits
        workspaceCode={import.meta.env.VITE_PRODUCT_FRUITS_CODE}
        language="en"
        user={userInfo}
      />
      <Outlet />
      {/* </PostHogProvider> */}
    </Elements>
  );
};
