import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiError, VotesPayload, voteForSupplierContact } from '@/lib/api';

const postVote = async (id: number, vote: VotesPayload) => {
  const { data } = await voteForSupplierContact(id, vote);
  return data;
};

export const useVoteForSupplierContact = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, ApiError, VotesPayload, unknown>({
    mutationFn: (vote: VotesPayload) => postVote(id, vote),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['supplier'],
      });
      queryClient.invalidateQueries({
        queryKey: ['distributor'],
      });
    },
  });
};
