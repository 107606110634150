import { useQuery } from '@tanstack/react-query';

import { recentSearchDemand } from '@/lib/api';

export const getRecentSearchDemand = async () => {
  const { data } = await recentSearchDemand();
  return data;
};

export const useRecentSearchDemand = () =>
  useQuery({
    queryKey: ['recent-search-demand'],
    queryFn: getRecentSearchDemand,
  });
