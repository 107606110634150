import { useQuery } from '@tanstack/react-query';

import { distributorDetails } from '@/lib/api';

export const getDistributor = async (id: number) => {
  const { data } = await distributorDetails(id);
  return data;
};

export const useDistributor = (id?: number) =>
  useQuery({
    queryKey: ['distributor', id],
    queryFn: () => getDistributor(id!),
    enabled: !!id,
  });
