import { useQuery } from '@tanstack/react-query';

import { productTypeDetails } from '@/lib/api';

export const postProductTypeDetails = async (id: number) => {
  const { data } = await productTypeDetails(id);
  return data;
};

export const useProductTypeDetails = (id: number) => {
  return useQuery({
    queryKey: ['product-type-details', id],
    queryFn: () => postProductTypeDetails(id),
  });
};
