import { useQuery } from '@tanstack/react-query';

import { listOfProductCategories } from '@/lib/api';

export const getProductCategories = async () => {
  const { data } = await listOfProductCategories();
  return data;
};

export const useProductCategories = () =>
  useQuery({
    queryKey: ['product-categories'],
    queryFn: getProductCategories,
  });
