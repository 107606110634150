import { useMutation } from '@tanstack/react-query';

import {
  AddOnCreditsCheckoutPayload,
  AddOnCreditsPayment,
  ApiError,
  createAddOnCreditsCheckoutSession,
} from '@/lib/api';

const postCreateAddOnCreditsCheckout = async (
  payload: AddOnCreditsCheckoutPayload
) => {
  const { data } = await createAddOnCreditsCheckoutSession(payload);
  return data;
};

export const useCreateAddOnCreditsCheckout = () =>
  useMutation<
    AddOnCreditsPayment,
    ApiError,
    AddOnCreditsCheckoutPayload,
    unknown
  >({
    mutationFn: postCreateAddOnCreditsCheckout,
  });
