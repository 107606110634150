import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { Loader2 } from 'lucide-react';
import * as z from 'zod';

import { PasswordInput } from '@/components/password-imput';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useToast } from '@/components/ui/use-toast';
import { useUserContext } from '@/context/user';

import { useSignIn, useSignUp } from '..';

const formSchema = z.object({
  firstName: z.string().min(2).max(50),
  email: z.string().min(2).max(50),
  password: z.string().min(8).max(50),
  privacyPolicy: z.boolean().refine((value) => value === true, {
    message: 'You must agree to the privacy policy',
  }),
});

export const SignUpForm = () => {
  const { setUser } = useUserContext();

  const { email } = useParams();

  const { toast, dismiss } = useToast();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      firstName: '',
      email: email ?? '',
      password: '',
      privacyPolicy: false,
    },
  });

  const {
    mutate: mutateSignUp,
    data: signUpData,
    isPending: isSignUpPending,
    error: signUpError,
  } = useSignUp();

  const {
    mutate: mutateSignIn,
    data: signInData,
    isPending: isSignInPending,
    error: signInError,
  } = useSignIn();

  useEffect(() => {
    if (signUpData?.status) {
      mutateSignIn({
        email: form.getValues('email'),
        password: form.getValues('password'),
      });
    }
  }, [form, mutateSignIn, signUpData]);

  useEffect(() => {
    if (signInData) {
      setUser(signInData);
    }
  }, [setUser, signInData]);

  useEffect(() => {
    if (signInError) {
      toast({
        description:
          'Error: ' + signInError.response?.data.message || signInError.message,
        variant: 'destructive',
      });
    } else {
      dismiss();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signInError]);

  useEffect(() => {
    if (signUpError) {
      toast({
        description:
          'Error: ' + signUpError.response?.data.message || signUpError.message,
        variant: 'destructive',
      });
    } else {
      dismiss();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signUpError]);

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    mutateSignUp(values);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="firstName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter Name"
                  autoComplete="name"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {!email && (
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter Email Address"
                    autoComplete="email"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem className="mb-1.5">
              <FormLabel>Password</FormLabel>
              <FormControl>
                <PasswordInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="privacyPolicy"
          render={({ field }) => (
            <FormItem className="mb-1.5">
              <div className="flex items-center gap-2">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <FormLabel
                  className="group text-sm font-medium
                leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  I agree to the{' '}
                  <a
                    href="https://supplierhq.io/privacy-policy"
                    target="_blank"
                    className="text-primary transition-opacity hover:opacity-80 group-[.text-destructive]:font-bold group-[.text-destructive]:text-destructive"
                  >
                    Privacy Policy
                  </a>
                </FormLabel>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button
          type="submit"
          className="w-full"
          disabled={isSignUpPending || isSignInPending}
        >
          {(isSignUpPending || isSignInPending) && (
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          )}
          Sign Up
        </Button>

        <p className="text-center text-sm text-muted-foreground">
          Already have an account?{' '}
          <Link
            to="/sign-in"
            className="text-sm text-primary transition-opacity hover:opacity-80"
          >
            Sign In
          </Link>
        </p>
      </form>
    </Form>
  );
};
