import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiError, FavoriteFolder, deleteFavoriteFolder } from '@/lib/api';

const postFavoriteFolder = async (id: number) => {
  const { data } = await deleteFavoriteFolder(id);
  return data;
};

export const useDeleteFavoriteFolder = () => {
  const queryClient = useQueryClient();

  return useMutation<FavoriteFolder, ApiError, number, unknown>({
    mutationFn: postFavoriteFolder,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['favorite-folders'],
      });
    },
  });
};
