import { useQuery } from '@tanstack/react-query';

import { academy } from '@/lib/api';

export const getAcademy = async () => {
  const { data } = await academy();
  return data;
};

export const useAcademy = () =>
  useQuery({
    queryKey: ['academy'],
    queryFn: getAcademy,
  });
