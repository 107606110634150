import { Link, isRouteErrorResponse, useRouteError } from 'react-router-dom';

import { FileWarning } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { ROUTES } from '@/constants/routes';

export function RootBoundary() {
  const error = useRouteError();

  let title = 'Something went wrong';

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      title = 'Page Not Found';
    }

    if (error.status === 401 || error.status === 405) {
      title = "You aren't authorized to see this";
    }

    if (error.status === 503) {
      title = 'Looks like our API is down';
    }

    if (error.status === 418) {
      title = '🫖';
    }
  }

  return (
    <>
      <div className="flex h-dvh items-center justify-center">
        <div className="max-w-80 text-center">
          <div className="mb-6 space-y-4">
            <FileWarning className="m-auto size-20 text-primary" />
            <h1 className="text-xl font-semibold ">{title}</h1>
            <p className="text-sm text-muted-foreground">
              <a className="text-primary" href="mailto:hello@supplierhq.io">
                Email Us
              </a>{' '}
              if you feel like this is a mistake.
            </p>
          </div>

          <Button className="w-full" asChild>
            <Link to={ROUTES.SUPPLIERS}>Go to Dashboard</Link>
          </Button>
        </div>
      </div>
    </>
  );
}
