import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { Loader2 } from 'lucide-react';
import * as z from 'zod';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import { useToast } from '@/components/ui/use-toast';

import { useSuggestCorrection } from '..';

const formSchema = z.object({
  margin: z.string(),
  marginIncludesShipping: z.string(),
  comment: z.string(),
});

interface SuggestCorrectionFormProps {
  supplier: string;
  onSuccess?: () => void;
}

export const SuggestCorrectionForm = ({
  supplier,
  onSuccess,
}: SuggestCorrectionFormProps) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      margin: '',
      marginIncludesShipping: 'TBD',
      comment: '',
    },
  });

  const { mutate, data, isPending, error } = useSuggestCorrection();

  const { toast, dismiss } = useToast();

  useEffect(() => {
    if (data && onSuccess) {
      toast({
        description: data,
      });
      onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, onSuccess]);

  useEffect(() => {
    if (error) {
      toast({
        description:
          'Error: ' + (error.response?.data.message || error.message),
        variant: 'destructive',
      });
    } else {
      dismiss();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    mutate({
      ...values,
      supplier,
    });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="grid gap-4">
        <FormField
          control={form.control}
          name="margin"
          render={({ field }) => (
            <FormItem className="flex items-center gap-4">
              <FormLabel>Margin</FormLabel>
              <span className="col-span-2 flex items-center gap-3">
                <FormControl>
                  <Input
                    type="number"
                    min={0}
                    max={99}
                    placeholder="e.g."
                    className="h-9 max-w-20"
                    {...field}
                  />
                </FormControl>
                %
              </span>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="marginIncludesShipping"
          render={({ field }) => (
            <FormItem className="flex items-center gap-4">
              <FormLabel> Margin Includes Shipping</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger className="h-9 max-w-20">
                    <SelectValue />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="Yes">Yes</SelectItem>
                    <SelectItem value="No">No</SelectItem>
                    <SelectItem value="TBD">TBD</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="comment"
          render={({ field }) => (
            <FormItem className="space-y-2">
              <FormLabel>Comments</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Any bit of information helps!"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" disabled={isPending}>
          {isPending && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
          Submit
        </Button>
      </form>
    </Form>
  );
};
