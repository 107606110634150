import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { UserProvider } from './context/user';
import './global.css';
import { queryClient } from './lib/query-client';
import './lib/sentry';
import { router } from './router';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <RouterProvider router={router} />
      </UserProvider>
      <ReactQueryDevtools initialIsOpen={true} buttonPosition="bottom-left" />
    </QueryClientProvider>
  </React.StrictMode>
);
