import { useEffect } from 'react';

import { Row } from '@tanstack/react-table';
import { ThumbsDown, ThumbsUp } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { Representative, Votes, VotesPayload } from '@/lib/api';
import { cn } from '@/lib/utils';

import { useVoteForSupplierContact } from '../..';

interface DataTableRowActionsProps {
  row: Row<Representative>;
  type?: Votes;
}

export function SupplierContactsTableRowVote({
  row,
  type = Votes.UPVOTE,
}: DataTableRowActionsProps) {
  const isUpVote = type === Votes.UPVOTE;

  const votes = row.getValue<number>(type);

  const isVoted = isUpVote
    ? row.original.votedByUser === VotesPayload.UPVOTE
    : row.original.votedByUser === VotesPayload.DOWNVOTE;

  const { mutate, error } = useVoteForSupplierContact(row.original.id);
  const { toast, dismiss } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        description:
          'Error: ' + (error.response?.data.message || error.message),
        variant: 'destructive',
      });
    } else {
      dismiss();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <Button
      variant="ghost"
      className={cn('mr-[-6px] h-8 w-8 space-x-1', isVoted && 'text-primary')}
      onClick={() =>
        mutate(isUpVote ? VotesPayload.UPVOTE : VotesPayload.DOWNVOTE)
      }
    >
      <span className="sr-only">{isUpVote ? 'Like' : 'Dislike'}</span>
      {isUpVote ? (
        <ThumbsUp className="min-h-4 min-w-4" />
      ) : (
        <ThumbsDown className="min-h-4 min-w-4" />
      )}
      <span>{votes}</span>
    </Button>
  );
}
