import { useMutation } from '@tanstack/react-query';

import {
  ApiError,
  ForgotPassword,
  ResetPasswordPayload,
  resetPassword,
} from '@/lib/api';

const postResetPassword = async (payload: ResetPasswordPayload) => {
  const { data } = await resetPassword(payload);
  return data;
};

export const useResetPassword = () =>
  useMutation<ForgotPassword, ApiError, ResetPasswordPayload, unknown>({
    mutationFn: postResetPassword,
  });
