import { useQuery } from '@tanstack/react-query';

import { userDetails } from '@/lib/api';

const getUser = async () => {
  const { data } = await userDetails();
  return data;
};

export const useUser = () =>
  useQuery({
    queryKey: ['user'],
    queryFn: getUser,
  });
