import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { Loader2 } from 'lucide-react';
import * as z from 'zod';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useToast } from '@/components/ui/use-toast';

import { useForgotPassword } from '..';

const formSchema = z.object({
  email: z.string().min(2).max(50),
});

export const ForgotPasswordForm = () => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      email: '',
    },
  });

  const { mutate, data, isPending, error } = useForgotPassword();

  const { toast, dismiss } = useToast();

  useEffect(() => {
    if (data) {
      toast({
        description: data.message,
        variant: data.status === false ? 'destructive' : 'default',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (error) {
      toast({
        description:
          'Error: ' + (error.response?.data.message || error.message),
        variant: 'destructive',
      });
    } else {
      dismiss();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    mutate(values);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter Email Address"
                  autoComplete="email"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit" className="w-full" disabled={isPending}>
          {isPending && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
          Reset
        </Button>

        <p className="text-center text-sm text-muted-foreground">
          Back to{' '}
          <Link
            to="/sign-in"
            className="text-sm text-primary transition-opacity hover:opacity-80"
          >
            Sign In
          </Link>
        </p>
      </form>
    </Form>
  );
};
