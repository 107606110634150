import { CircleHelp, LucideProps } from 'lucide-react';

import { cn } from '@/lib/utils';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './ui/tooltip';

interface CircleHelpTooltipProps extends LucideProps {
  tooltip?: string;
}

export const CircleHelpTooltip = ({
  tooltip,
  className,
  ...props
}: CircleHelpTooltipProps) => {
  if (!tooltip) return null;

  return (
    <TooltipProvider delayDuration={300} skipDelayDuration={100}>
      <Tooltip>
        <TooltipTrigger tabIndex={-1}>
          <CircleHelp
            className={cn('h-3 w-3 text-muted-foreground', className)}
            {...props}
          />
        </TooltipTrigger>
        <TooltipContent className="max-w-sm">{tooltip}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
