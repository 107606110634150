import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { CirclePlus, CornerDownLeft, Loader2 } from 'lucide-react';
import * as z from 'zod';

import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useToast } from '@/components/ui/use-toast';
import { FavoritesType } from '@/lib/api';

import {
  useCreateFavoriteFolder,
  useFavoriteFolders,
  useFavorites,
} from '../hooks';

interface FavoriteFolderSelectProps {
  type: FavoritesType;
  value?: number[];
  onValueChange: Dispatch<SetStateAction<number[]>>;
  showAll?: boolean;
}

const createFormSchema = z.object({
  name: z.string().min(1).max(50),
});

// TODO: add error handlers
export const FavoriteFolderSelect: FC<FavoriteFolderSelectProps> = ({
  type,
  value,
  onValueChange,
  showAll = false,
}) => {
  const [isCreateFolderVisible, setIsCreateFolderVisible] = useState(false);

  const { data: foldersData } = useFavoriteFolders(type);

  const { data: favoritesData } = useFavorites(type);

  const {
    mutate: mutateCreate,
    data: createMutationData,
    isPending,
    error,
  } = useCreateFavoriteFolder();

  const { toast, dismiss } = useToast();

  const createForm = useForm<z.infer<typeof createFormSchema>>({
    resolver: zodResolver(createFormSchema),
    values: {
      name: '',
    },
  });

  useEffect(() => {
    if (createMutationData) {
      onValueChange((value = []) => [...value, createMutationData.folder.id]);
      setIsCreateFolderVisible(false);
      createForm.reset();
    }
  }, [createMutationData, createForm, onValueChange]);

  useEffect(() => {
    if (error) {
      toast({
        description: 'Error: ' + (error.response?.data.error || error.message),
        variant: 'destructive',
      });
      createForm.reset();
    } else {
      dismiss();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, createForm]);

  const onSubmit = (values: z.infer<typeof createFormSchema>) => {
    mutateCreate(values.name);
  };

  return (
    <>
      {showAll && (
        <div className="flex items-center space-x-2">
          <Checkbox id="all" checked disabled />
          <label
            htmlFor="all"
            className="flex flex-1 items-center justify-between text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            All
            <span className="block min-w-4 rounded bg-primary/10 px-[2px] py-[3px] text-center text-[10px] text-primary">
              {favoritesData?.length}
            </span>
          </label>
        </div>
      )}
      {foldersData?.map(({ folderId, folderName, favorites }) => (
        <div
          className="flex items-center space-x-2"
          key={folderId + folderName}
        >
          <Checkbox
            id={folderId.toString()}
            checked={value?.includes(folderId)}
            onCheckedChange={(checked) => {
              if (checked) {
                onValueChange?.([...(value || []), folderId]);
              } else {
                onValueChange?.(value?.filter((v) => v !== folderId) ?? []);
              }
            }}
          />
          <label
            htmlFor={folderId.toString()}
            className="flex flex-1 items-center justify-between text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            {folderName}
            <span className="block min-w-4 rounded bg-primary/10 px-[2px] py-[3px] text-center text-[10px] text-primary">
              {favorites?.length}
            </span>
          </label>
        </div>
      ))}
      {isCreateFolderVisible ? (
        <Form {...createForm}>
          <form
            onSubmit={createForm.handleSubmit(onSubmit)}
            className="flex items-center justify-between gap-2"
          >
            <CirclePlus className="size-4 text-primary" />
            <FormField
              control={createForm.control}
              name="name"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormControl>
                    <Input
                      placeholder="Enter Folder Name"
                      className="h-4 border-none p-0 text-sm focus-visible:ring-0 focus-visible:ring-offset-0"
                      required
                      autoFocus
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              size="xs"
              className="flex h-4 gap-1 rounded px-1 py-[3px] text-[10px] leading-none"
              disabled={isPending}
            >
              {isPending ? (
                <Loader2 className="size-2.5 animate-spin" />
              ) : (
                <CornerDownLeft size={10} />
              )}
              Create
            </Button>
          </form>
        </Form>
      ) : (
        <button
          className="flex items-center gap-2 text-sm font-medium text-primary transition-opacity hover:opacity-85"
          type="button"
          onClick={() => setIsCreateFolderVisible(true)}
        >
          <CirclePlus size={16} />
          Create New Folder
        </button>
      )}
    </>
  );
};
