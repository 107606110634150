import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiError, SignInPayload, User, signIn } from '@/lib/api';

const postSignIn = async (payload: SignInPayload) => {
  const { data } = await signIn(payload);
  return data;
};

export const useSignIn = () => {
  const queryClient = useQueryClient();

  return useMutation<User, ApiError, SignInPayload, unknown>({
    mutationFn: postSignIn,
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });
};
