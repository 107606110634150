import { forwardRef, useEffect } from 'react';

import { useStripe } from '@stripe/react-stripe-js';

import AddSearches from '@/assets/icons/add-searches.svg?react';
import Flame from '@/assets/icons/flame.svg?react';
import { CircleHelpTooltip } from '@/components/circle-help-tooltip';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { useUserContext } from '@/context/user';
import { useCreateAddOnSearchQueriesCheckout } from '@/features/subscription';

export const SearchLimit = forwardRef<HTMLButtonElement, object>(({}, ref) => {
  const { user } = useUserContext();

  const stripe = useStripe();

  const { mutate, data } = useCreateAddOnSearchQueriesCheckout();

  useEffect(() => {
    if (data) {
      try {
        stripe?.redirectToCheckout({
          sessionId: data.session.id,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [data, stripe]);

  if (!user) return null;

  const { trendSearchesAddOn, trendSearchesRemaining } = user;

  const limitColor = user
    ? trendSearchesRemaining > 9
      ? 'primary'
      : trendSearchesRemaining > 2
        ? 'warning'
        : 'destructive'
    : 'primary';

  return (
    <Dialog>
      {/* NOTE: fix for tailwind */}
      <span className="bg-destructive/10 text-destructive"></span>
      <span className="bg-warning/10 text-warning"></span>
      <div
        className={`flex gap-2 rounded-md bg-${limitColor}/10 items-center px-1.5  py-[5px]`}
      >
        <div
          className={`flex gap-0.5 text-sm font-medium leading-none text-${limitColor} items-center`}
        >
          <span>
            {Number(trendSearchesRemaining) + Number(trendSearchesAddOn)}
          </span>
          <span className={`text-${limitColor}/25`}>/</span>
          <span>
            {Number(user.plan?.searches) + Number(trendSearchesAddOn)}
          </span>
          <CircleHelpTooltip
            tooltip={`Monthly searches plus ${trendSearchesAddOn} add-on searches`}
            className={`text-${limitColor}`}
          />
        </div>
        <DialogTrigger asChild>
          <Button className="w-full" size="xs" ref={ref}>
            Add
          </Button>
        </DialogTrigger>
      </div>
      <DialogContent className="gap-6 sm:max-w-[500px]">
        <DialogHeader className="items-center space-y-4">
          <AddSearches />
          <DialogTitle>Add More Searches</DialogTitle>
          <DialogDescription>
            Choose the number of searches you would like to add.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 sm:grid-cols-2 sm:grid-rows-2">
          {!!user.plan?.search_credit_price_50 && (
            <Badge
              variant="outline"
              className="justify-between rounded-md p-3 text-sm"
            >
              50 Searches
              <Button
                size="sm"
                className="px-4 py-2 text-xs"
                onClick={() => mutate({ quantity: 50 })}
              >
                ${user.plan?.search_credit_price_50}
              </Button>
            </Badge>
          )}
          {!!user.plan?.search_credit_price_150 && (
            <Badge
              variant="outline"
              className="justify-between rounded-md p-3 text-sm"
            >
              150 Searches
              <Button
                size="sm"
                className="px-4 py-2 text-xs"
                onClick={() => mutate({ quantity: 150 })}
              >
                ${user.plan?.search_credit_price_150}
              </Button>
            </Badge>
          )}
          {!!user.plan?.search_credit_price_500 && (
            <Badge
              variant="outline"
              className="justify-between rounded-md p-3 text-sm"
            >
              500 Searches
              <Button
                size="sm"
                className="relative px-4 py-2 text-xs"
                onClick={() => mutate({ quantity: 500 })}
              >
                <Flame className="absolute -left-2 -top-2" />$
                {user.plan?.search_credit_price_500}
              </Button>
            </Badge>
          )}
          {!!user.plan?.search_credit_price_1000 && (
            <Badge
              variant="outline"
              className="justify-between rounded-md p-3 text-sm"
            >
              1000 Searches
              <Button
                size="sm"
                className="px-4 py-2 text-xs"
                onClick={() => mutate({ quantity: 1000 })}
              >
                ${user.plan?.search_credit_price_1000}
              </Button>
            </Badge>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
});
