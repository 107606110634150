import { useMutation } from '@tanstack/react-query';

import {
  ApiError,
  SuggestCorrectionPayload,
  suggestCorrection,
} from '@/lib/api';

const postSuggestCorrection = async (payload: SuggestCorrectionPayload) => {
  const { data } = await suggestCorrection(payload);
  return data;
};

export const useSuggestCorrection = () =>
  useMutation<string, ApiError, SuggestCorrectionPayload, unknown>({
    mutationFn: postSuggestCorrection,
  });
