import { browserTracingIntegration, init } from '@sentry/react';

import { isDevelopment, isStaging } from '@/utils/environment';

init({
  dsn: 'https://3d7217ad58f851417d02ecef71592167@o4506943930957824.ingest.us.sentry.io/4507106002272256',
  integrations: [browserTracingIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/supplierhq\.io\/api/],
  // Custom configuration
  enabled: isDevelopment ? false : true,
  environment: isStaging ? 'staging' : 'production',
});
