import { useQuery } from '@tanstack/react-query';

import { FavoritesType, favoriteFolders } from '@/lib/api';

export const getFavoriteFolders = async (type?: FavoritesType) => {
  const { data } = await favoriteFolders(type);
  return data;
};

export const useFavoriteFolders = (type?: FavoritesType) =>
  useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['favorite-folders', type].filter(Boolean),
    queryFn: () => getFavoriteFolders(type),
  });
