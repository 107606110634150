import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { User } from '@/lib/api';

interface ProviderProps {
  children: ReactNode;
}

interface UserContext {
  user: User | null;
  setUser: Dispatch<SetStateAction<User | null>>;
}

const UserContext = createContext<UserContext | null>(null);

export const UserProvider = ({ children }: ProviderProps) => {
  const [user, setUser] = useState<User | null>(() => {
    const localUser = localStorage.getItem('user');

    if (localUser) {
      return JSON.parse(localUser);
    }

    return null;
  });

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    }
  }, [user]);

  const value = useMemo(
    () => ({
      user,
      setUser,
    }),
    [user]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};
