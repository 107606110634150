import { Navigate, Outlet } from 'react-router-dom';

import { LifeBuoy, Mail, MessageCircle } from 'lucide-react';

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { ROUTES } from '@/constants/routes';
import { useUserContext } from '@/context/user';

export const AuthLayout = () => {
  const { user } = useUserContext();

  if (user) return <Navigate to={ROUTES.SUPPLIERS} replace={true} />;

  const handleOpenChat = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.Intercom('show');
  };

  return (
    <main className="grid bg-card md:grid-cols-2">
      <Outlet />
      <div className="hidden items-center justify-center bg-[url('/img/bg/mask-bg.svg')] bg-cover px-4 sm:flex">
        <Card className="max-w-96">
          <CardHeader>
            <CardTitle>Need help?</CardTitle>
            <CardDescription>
              Get help with setting up and using Supplier HQ for your business.
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-2">
            <a
              className="flex items-center gap-2 text-sm hover:text-primary"
              href="mailto:hello@supplierhq.io"
            >
              <Mail size={16} className="text-primary" />
              Email Us
            </a>
            <a
              className="flex items-center gap-2 text-sm hover:text-primary"
              href="#intercom"
              onClick={handleOpenChat}
            >
              <MessageCircle size={16} className="text-primary" />
              Chat with Us
            </a>
            <a
              className="flex items-center gap-2 text-sm hover:text-primary"
              href="https://help.highticket.io"
              target="_blank"
            >
              <LifeBuoy size={16} className="text-primary" />
              Visit Help Center
            </a>
          </CardContent>
        </Card>
      </div>
    </main>
  );
};
