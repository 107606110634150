export const ROUTES = {
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  INVITE: '/invite/:email',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  SUPPLIERS: '/suppliers',
  SUPPLIER: '/suppliers/:id',
  DISTRIBUTORS: '/distributors',
  DISTRIBUTOR: '/distributors/:id',
  PRODUCT_CATEGORIES: '/product-explorer',
  PRODUCT_CATEGORY: '/product-explorer/:id',
  FAVORITE_SUPPLIERS: '/favorite-suppliers',
  FAVORITE_DISTRIBUTORS: '/favorite-distributors',
  SETTINGS: '/settings',
  SUBSCRIPTION: '/subscription',
  ADD_ON_CREDITS_SUCCESS: '/add-on-credits-success',
  WEEKLY_PRODUCTS: '/weekly-products',
  SUBSCRIPTION_SUCCESS: '/success',
  SEARCH_DEMAND: '/search-demand',
  FAVORITE_CRM: '/favorite-crm',
  // NOTE: old urls
  DASHBOARD: '/dashboard',
  FAVORITES: '/favorites',
  SIGN_UP_OLD: '/signup',
  SIGN_IN_OLD: '/signin',
};
