import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiError, FavoriteFolder, updateFavoriteFolder } from '@/lib/api';

const patchFavoriteFolder = async (id: number, name: string) => {
  const { data } = await updateFavoriteFolder(id, name);
  return data;
};

export const useUpdateFavoriteFolder = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation<FavoriteFolder, ApiError, string, unknown>({
    mutationFn: (name) => patchFavoriteFolder(id, name),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['favorite-folders'],
      });
    },
  });
};
