import { useQuery } from '@tanstack/react-query';

import { FavoritesType, listOfFavorites } from '@/lib/api';

export const getFavorites = async (type?: FavoritesType, folderId?: string) => {
  const { data } = await listOfFavorites(type, folderId);
  return data;
};

export const useFavorites = (type?: FavoritesType, folderId?: string) => {
  const queryKey = ['favorites', type, folderId].filter(Boolean);

  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: queryKey,
    queryFn: () => getFavorites(type, folderId),
  });
};
