import { useUserContext } from '@/context/user';

export const useSignOut = () => {
  const { setUser } = useUserContext();

  return () => {
    localStorage.removeItem('user');
    setUser(null);
  };
};
