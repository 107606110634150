import { InputHTMLAttributes, MouseEvent, forwardRef, useState } from 'react';

import { Eye, EyeOff } from 'lucide-react';

import { cn } from '@/lib/utils';

import { Input } from './ui/input';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}

export const PasswordInput = forwardRef<HTMLInputElement, Props>(
  ({ className, ...rest }, ref) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const handlePasswordVisibility = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setIsPasswordVisible(!isPasswordVisible);
    };

    return (
      <div className={cn('relative', className)}>
        <Input
          ref={ref}
          type={isPasswordVisible ? 'text' : 'password'}
          placeholder="Enter Password"
          autoComplete="current-password"
          {...rest}
        />
        <button
          className="absolute inset-y-0 right-0 flex items-center px-4 text-muted-foreground"
          onClick={handlePasswordVisibility}
        >
          {isPasswordVisible ? <Eye size={18} /> : <EyeOff size={18} />}
        </button>
      </div>
    );
  }
);
