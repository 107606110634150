import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { Loader2, Pencil, Plus, Trash2 } from 'lucide-react';
import * as z from 'zod';

import { Button } from '@/components/ui/button';
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { useToast } from '@/components/ui/use-toast';
import { FavoritesType } from '@/lib/api';

import {
  useCreateFavoriteFolder,
  useDeleteFavoriteFolder,
  useFavoriteFolders,
  useFavorites,
  useUpdateFavoriteFolder,
} from '../hooks';

const FolderSettings = ({
  id,
  name,
  onSuccess,
}: {
  id?: number;
  name: string;
  onSuccess: () => void;
}) => {
  const { mutateAsync: mutateDeleteAsync, isPending: isDeletePending } =
    useDeleteFavoriteFolder();

  const handleDelete = async () => {
    await mutateDeleteAsync(id!);
    onSuccess();
  };

  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);

  const {
    mutate: mutateUpdate,
    data: updateMutationData,
    isPending: isUpdatePending,
    error,
  } = useUpdateFavoriteFolder(id!);

  const { toast, dismiss } = useToast();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      name,
    },
  });

  useEffect(() => {
    if (updateMutationData) {
      setIsUpdateDialogOpen(false);
      form.reset();
    }
  }, [updateMutationData, form]);

  useEffect(() => {
    if (error) {
      toast({
        description: 'Error: ' + (error.response?.data.error || error.message),
        variant: 'destructive',
      });
      form.reset();
    } else {
      dismiss();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, form]);

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    mutateUpdate(values.name);
  };

  if (id) {
    return (
      <Dialog open={isUpdateDialogOpen} onOpenChange={setIsUpdateDialogOpen}>
        <DialogTrigger asChild>
          <div
            role="button"
            className="cursor-pointer transition-opacity hover:opacity-85"
          >
            <Pencil className="h-3.5 w-3.5" />
            <span className="sr-only">Edit</span>
          </div>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px]">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <DialogHeader className="mb-4 space-y-2">
                <DialogTitle className="text-center leading-normal">
                  Edit Folder
                </DialogTitle>
                <DialogDescription className="text-center">
                  Use folders to keep and sort suppliers together.
                </DialogDescription>
              </DialogHeader>
              <div className="grid gap-2 py-4">
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Folder Name</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter Folder Name"
                          className="col-span-3"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <Card className="mb-6 shadow-none">
                <CardHeader className="flex flex-row flex-wrap items-center justify-between gap-2 p-3">
                  <div className="space-y-0.5">
                    <CardTitle className="text-xs leading-5">
                      Delete Folder Permanently
                    </CardTitle>
                    <CardDescription className="text-[10px] leading-[10px]">
                      All suppliers will moved to the “All” folder.
                    </CardDescription>
                  </div>
                  <Button
                    className="flex h-8 gap-1"
                    size="sm"
                    variant="destructive"
                    type="button"
                    onClick={handleDelete}
                    disabled={isDeletePending}
                  >
                    {isDeletePending ? (
                      <Loader2 className="h-3 w-3 animate-spin" />
                    ) : (
                      <Trash2 size={12} />
                    )}
                    Delete
                  </Button>
                </CardHeader>
              </Card>
              <DialogFooter>
                <DialogClose asChild>
                  <Button type="button" variant="secondary" className="w-full">
                    Close
                  </Button>
                </DialogClose>
                <Button
                  type="submit"
                  className="w-full"
                  disabled={isUpdatePending}
                >
                  {isUpdatePending && (
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  )}
                  Confirm
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    );
  }
};

interface FavoritesFoldersProps {
  type?: FavoritesType;
}

const formSchema = z.object({
  name: z.string().min(1).max(50),
});

export const FavoriteFolders: FC<FavoritesFoldersProps> = ({ type }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedFolder, setSelectedFolder] = useState(
    () => searchParams.get('folderId') ?? 'all'
  );

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const { data: favoritesData } = useFavorites(type);

  const { data: foldersData } = useFavoriteFolders(type);

  const {
    mutate,
    data: createMutationData,
    isPending,
    error,
  } = useCreateFavoriteFolder();

  const { toast, dismiss } = useToast();

  useEffect(() => {
    if (selectedFolder !== 'all') {
      setSearchParams({ folderId: selectedFolder });
    } else {
      setSearchParams({});
    }
  }, [setSearchParams, selectedFolder]);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      name: '',
    },
  });

  useEffect(() => {
    if (createMutationData) {
      setIsCreateDialogOpen(false);
      form.reset();
    }
  }, [createMutationData, form]);

  useEffect(() => {
    if (error) {
      toast({
        description: 'Error: ' + (error.response?.data.error || error.message),
        variant: 'destructive',
      });
      form.reset();
    } else {
      dismiss();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, form]);

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    mutate(values.name);
  };

  return (
    <div className="flex flex-wrap items-center gap-2 p-6 pt-0">
      <ToggleGroup
        type="single"
        value={selectedFolder}
        onValueChange={(value) => value && setSelectedFolder(value)}
        className="flex-wrap justify-start"
      >
        <ToggleGroupItem
          variant="outline"
          size="sm"
          className="group flex h-8 gap-1 border-primary/20 bg-primary/5 px-2 text-primary hover:bg-primary/10 hover:text-primary data-[state=on]:border-primary data-[state=on]:bg-primary data-[state=on]:text-white"
          value="all"
        >
          <span className="block min-w-4 rounded-md bg-primary/10 px-0.5 py-[3px] text-[10px] font-medium leading-none text-primary group-data-[state=on]:bg-white/25 group-data-[state=on]:text-white">
            {favoritesData?.length ?? 0}
          </span>
          All
        </ToggleGroupItem>
        {foldersData?.map(({ folderId, folderName, favorites }) => (
          <ToggleGroupItem
            variant="outline"
            size="sm"
            className="group flex h-8 gap-1 border-primary/20 bg-primary/5 px-2 text-primary hover:bg-primary/10 hover:text-primary data-[state=on]:border-primary data-[state=on]:bg-primary data-[state=on]:text-white"
            value={folderId.toString()}
            key={folderId}
          >
            <span className="block min-w-4 rounded-md bg-primary/10 px-0.5 py-[3px] text-[10px] font-medium leading-none text-primary group-data-[state=on]:bg-white/25 group-data-[state=on]:text-white">
              {favorites.length ?? 0}
            </span>
            {folderName}
            <FolderSettings
              id={folderId == Number(selectedFolder) ? folderId : undefined}
              name={folderName}
              onSuccess={() => setSelectedFolder('all')}
            />
          </ToggleGroupItem>
        ))}
      </ToggleGroup>

      <Dialog open={isCreateDialogOpen} onOpenChange={setIsCreateDialogOpen}>
        <DialogTrigger asChild>
          <Button variant="outline" size="sm" className="h-8">
            <Plus className="mr-1 h-3.5 w-3.5 text-primary" /> Create Folder
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px]">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <DialogHeader className="mb-8 space-y-2">
                <DialogTitle className="text-center leading-normal">
                  Create Folder
                </DialogTitle>
                <DialogDescription className="text-center">
                  Use folders to keep and sort suppliers together.
                </DialogDescription>
              </DialogHeader>
              <div className="mb-6 grid gap-2">
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Folder Name</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter Folder Name"
                          className="col-span-3"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <DialogFooter>
                <DialogClose asChild>
                  <Button type="button" variant="secondary" className="w-full">
                    Close
                  </Button>
                </DialogClose>
                <Button type="submit" className="w-full" disabled={isPending}>
                  {isPending && (
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  )}
                  Create
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
