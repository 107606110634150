import { ColumnDef } from '@tanstack/react-table';
import { ArrowUpDown, Check } from 'lucide-react';

import Linkedin from '@/assets/icons/linkedin.svg?react';
import { Button } from '@/components/ui/button';
import { Representative, Votes } from '@/lib/api';

import { SupplierContactsTableRowVote } from './supplier-contacts-table-row-vote';

export const supplierContactsColumns: ColumnDef<Representative>[] = [
  {
    accessorKey: 'firstName',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          size="sm"
          className="font-normal"
        >
          Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => (
      <div className="font-medium">
        {row.getValue('firstName')} {row.original.lastName}
      </div>
    ),
  },
  {
    accessorKey: 'title',
    header: 'Title',
  },
  {
    accessorKey: 'email',
    header: 'Email',
    cell: ({ row }) => {
      const email = row.getValue<string>('email');
      return (
        <a href={`mailto:${email}`} className="text-primary hover:underline">
          {email}
        </a>
      );
    },
  },
  {
    accessorKey: 'phone',
    header: 'Phone',
    cell: ({ row }) => {
      const phone = row.getValue<string>('phone');
      return (
        <a
          href={`
          tel:${phone.replace(/\D/g, '')}
        `}
        >
          {phone}
        </a>
      );
    },
  },
  {
    accessorKey: 'linkedin',
    header: 'Social',
    cell: ({ row }) => {
      const linkedin = row.getValue<string>('linkedin');
      return (
        <a
          href={linkedin}
          target="_blank"
          rel="noreferrer"
          className="inline-block rounded-md bg-primary/5 p-[5px] text-primary"
        >
          <Linkedin />
        </a>
      );
    },
  },
  {
    accessorKey: 'isVerified',
    header: 'Verified',
    cell: ({ row }) => {
      const isVerified = row.getValue('isVerified');
      return (
        <div>
          {isVerified ? (
            <div className="bg-success/5 text-success inline-block rounded-md p-[5px]">
              <Check size={14} strokeWidth={3} />
            </div>
          ) : null}
        </div>
      );
    },
  },
  {
    accessorKey: Votes.UPVOTE,
    header: '',
    cell: ({ row }) => <SupplierContactsTableRowVote row={row} />,
  },
  {
    accessorKey: Votes.DOWNVOTE,
    header: '',
    cell: ({ row }) => (
      <SupplierContactsTableRowVote row={row} type={Votes.DOWNVOTE} />
    ),
  },
];
