import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiError, createFavoriteFolder } from '@/lib/api';

const postFavoriteFolder = async (name: string) => {
  const { data } = await createFavoriteFolder(name);
  return data;
};

export const useCreateFavoriteFolder = () => {
  const queryClient = useQueryClient();

  return useMutation<
    { folder: { id: number; name: string } },
    ApiError,
    string,
    unknown
  >({
    mutationFn: postFavoriteFolder,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['favorite-folders'],
      });
    },
  });
};
