import { useMutation } from '@tanstack/react-query';

import {
  ApiError,
  ForgotPassword,
  ForgotPasswordPayload,
  forgotPassword,
} from '@/lib/api';

const postForgotPassword = async (payload: ForgotPasswordPayload) => {
  const { data } = await forgotPassword(payload);
  return data;
};

export const useForgotPassword = () =>
  useMutation<ForgotPassword, ApiError, ForgotPasswordPayload, unknown>({
    mutationFn: postForgotPassword,
  });
